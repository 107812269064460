export class Hero {
  constructor() {
    this.$hero = document.querySelector(".hero")
    if (!this.$hero) {
      return
    }

    this.isCategoriesOpen = false

    this.$categoriesWrapper = this.$hero.querySelector(".hero__categories")
    this.$categoriesTrigger = this.$hero.querySelector(".hero__categories-toggler")

    this.init()
  }

  init() {
    if (this.$categoriesWrapper && this.$categoriesTrigger) {
      this.$categoriesTrigger.addEventListener("click", this.#categoriesTriggerClickHandle)
    }
  }

  #categoriesTriggerClickHandle = () => {
    if (this.isCategoriesOpen) {
      this.closeCategories()
    } else {
      this.openCategories()
    }
  }

  openCategories() {
    this.$categoriesWrapper.classList.add("_open")
    this.isCategoriesOpen = true
  }

  closeCategories() {
    this.$categoriesWrapper.classList.remove("_open")
    this.isCategoriesOpen = false
  }
}
