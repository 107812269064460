import { Navigation } from "./navigation"
import { Lodash } from "../lib/lodash"

export class Header {
  constructor() {
    /** @type { HTMLElement } */
    this.$header = document.querySelector(".header")
    if (!this.$header) {
      return console.warn("Can not initialize header without header", this)
    }

    this.isHidden = false
    this.headerHeight = 0

    /** @type { string } */
    this.headerHeightCssVar = "--header-height"
    /** @type { string } */
    this.hiddenVisibleVlass = "_hide"

    /** @type { number } */
    this.currentScrollPosition = window.scrollY

    this.navigation = new Navigation()

    this.init()
  }

  init() {
    this.setCssVars()
    this.toggleHeaderVisible()

    window.addEventListener("scroll", this.#scrollHandle)
    window.addEventListener("resize", this.#resizeHandle)
    window.addEventListener("mousemove", this.#onWindowMouseMove)
  }

  #scrollHandle = Lodash.throttle(() => {
    this.toggleHeaderVisible()
  }, 50)

  #resizeHandle = Lodash.throttle(() => {
    this.setCssVars()
  }, 100)

  #onWindowMouseMove = Lodash.throttle(({ clientY }) => {
    if (clientY < this.$header.offsetHeight) {
      this.show()
    }
  }, 100)

  toggleHeaderVisible() {
    if (window.scrollY < this.currentScrollPosition || window.scrollY < this.headerHeight) {
      this.show()
    } else {
      this.hide()
    }

    this.currentScrollPosition = window.scrollY
  }

  show() {
    if (!this.isHidden) {
      return
    }
    this.$header.classList.remove(this.hiddenVisibleVlass)
    this.isHidden = false
  }

  hide() {
    if (this.isHidden) {
      return
    }
    this.$header.classList.add(this.hiddenVisibleVlass)
    this.isHidden = true
  }

  setCssVars() {
    this.headerHeight = this.$header.offsetHeight
    document.documentElement.style.setProperty(this.headerHeightCssVar, `${this.headerHeight}px`)
  }
}
