import { Header } from "./modules/header"
import { Hero } from "./modules/hero"
import Swiper from "swiper"
import { Form } from "./components/forms/form"

/**
 * Show hidden elements as modals, popups, tooltips, etc.
 * In a first load webpage html elements loads before styles. That's all
 * elements of transition is jumping on a page.
 * @type { NodeListOf<HTMLElement> }
 * */
const hiddenElements = document.querySelectorAll(".important_none")

window.addEventListener("load", async () => {
  hiddenElements.forEach(($el) => $el.classList.remove("important_none"))

  /**
   * Initialize modules
   * */
  new Header()
  new Hero()

  /**
   *
   * */
  document.querySelectorAll(".js-from").forEach(($el) => {
    new Form({
      form: $el,
    })
  })

  /**
   * Initialize sliders
   * */
  new Swiper(".biggest-sales__slider", {
    spaceBetween: 12,
    slidesPerView: 1.1,
    breakpoints: {
      1400: {
        slidesPerView: 4,
        spaceBetween: 32,
      },
      1024: {
        slidesPerView: 3.25,
        spaceBetween: 24,
      },
      768: {
        slidesPerView: 2.5,
        spaceBetween: 20,
      },
      350: {
        slidesPerView: 1.3,
        spaceBetween: 20,
      },
    },
  })

  new Swiper(".reviews__slider", {
    spaceBetween: 12,
    slidesPerView: 1,
    breakpoints: {
      1400: {
        slidesPerView: 4,
        spaceBetween: 32,
      },
      1024: {
        slidesPerView: 3,
        spaceBetween: 24,
      },
      768: {
        slidesPerView: 2,
        spaceBetween: 20,
      },
      375: {
        slidesPerView: 1.25,
        spaceBetween: 20,
      },
    },
  })

  new Swiper(".services__slider", {
    spaceBetween: 12,
    slidesPerView: 1,
    breakpoints: {
      1400: {
        slidesPerView: 4,
        spaceBetween: 32,
      },
      1024: {
        slidesPerView: 3,
        spaceBetween: 24,
      },
      768: {
        slidesPerView: 2,
        spaceBetween: 20,
      },
      375: {
        slidesPerView: 1.25,
        spaceBetween: 20,
      },
    },
  })

  const el_pt = document.querySelector(".container")

  if (el_pt) {
    el_pt.classList.add("pt-header-height")
  }
})
