/**
 * @typedef defaultFormOptions
 * @property { HTMLElement | string } form - Will get forms element from DOM
 * @property { string } fieldSelector - Will get fields in forms
 */

/** @type defaultFormOptions */
export const defaultFormOptions = {
  form: ".js-forms",
  fieldSelector: ".js-field",
}
